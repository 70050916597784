import React from "react";
import { useEffect, useState, useContext, useRef } from "react";
import { AccountContext } from "../contexts/AccountContext";
import buildLink, { path } from "../urls";
import _axios from "../axios";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { GrSecure } from "react-icons/gr";
import { IoEye, IoEyeOff, IoWarning } from "react-icons/io5";
import { browserName, osName } from "react-device-detect";
import packageJson from "../../package.json";
import axios from "axios";
import NewLoader from "../components/NewLoader";


function Login() {
  const [state, dispatch] = useContext(AccountContext);
  const [loginLoading, setLoginLoading] = useState(false);


  const identifierRef = useRef("");
  const authRef = useRef("");
  const [counterOtp, setCounterOtp] = useState(0);
  const intervalRefSms = useRef(null);
  const [LoadingSendCode, setLoadingSendCode] = useState(false);
  const [phoneNotExist, setPhoneNotExist] = useState("");

  const [identifierToResetPass, setIdentifierToResetPass] = useState(null);
  const [error, setError] = useState(null);
  
  const handleSetError = (error) => {
    setError(error);
    setTimeout(() => {
      setError(null);
    }, 10000);
  };

 

  const navigate = useNavigate();
  const [type, setType] = useState("emailLogin");
  const width = window.innerWidth;
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleStartCountOtp = () => {
    intervalRefSms.current = 30;

    intervalRefSms.current = setInterval(() => {
      setCounterOtp((prevCounter) => {
        if (prevCounter === 1) {
          clearInterval(intervalRefSms.current);

          intervalRefSms.current = null;
          return 0;
        } else {
          return prevCounter - 1;
        }
      });
    }, 1000);
  };




  useEffect(() => {
    if (state.loged) {
      navigate("/");
    }
  }, [state.loged]);

  const getDeviceInfo = () => {
    return {
      loginChannel: osName + " - " + browserName,
    }; // osName provides the device's OS
  };

  // Login by email
  function loginByEmailOrTelephone(e, type) {
    setLoginLoading(true);
    e.preventDefault();
    const device_info = { user_agent: getDeviceInfo().loginChannel };
    let formData = {
      device_info: JSON.stringify(device_info),
      version_number: packageJson.version,
      login_channel: width > 650 ? "web_desktop" : "web_mobile",
      latitude: "",
      longitude: "",
      identifier: identifierRef.current.value,
      redirect: false,
      login_type: "default",
    };

    if (type === "email") {
      formData["authenticated_email"] = 1;
      formData["type_login"] = "email";
      formData["password"] = authRef.current.value;
      _axios
        .post(buildLink("login_email", undefined, window.innerWidth), formData)
        .then((response) => {
          const data = response.data;
          console.log(data);
          
          if (!data.success) {
            handleSetError(data?.errors["0"]?.errorMsg);
          } else {
            console.log(data);
            checkLogin();
          }
          setLoginLoading(false);
        });
    } else if (type === "telephone") {
      formData["authenticated_telephone"] = 1;
      formData["type_login"] = "telephone";
      formData["password"] = authRef.current.value;
      _axios
        .post(
          buildLink("login_telephone", undefined, window.innerWidth),
          formData
        )
        .then((response) => {
          const data = response.data;
          if (!data.success) {
            handleSetError(data?.errors["0"]?.errorMsg);
            setPhoneNotExist(formData.identifier);
          } else {
            console.log(data);
            checkLogin();
          }
          setLoginLoading(false);
        });
    }
    else if (type === "loginPhoneOtp") {
      formData["type_login"] = "otp";
      formData["otpNumber"] = authRef.current.value;
      formData["authenticated_telephone"] = 1;

      console.log(formData);
      
      _axios
        .post(
          buildLink("login_OTP", undefined, window.innerWidth),
          formData
        )
        .then((response) => {
          const data = response.data;
          if (!data.success) {
            handleSetError(data?.errors["0"]?.errorMsg);
            setPhoneNotExist(formData.identifier);
          } else {
            console.log(data);
            checkLogin();
          }
          setLoginLoading(false);
        });

    }
  }

  const sendOtpForLoginTelephon = async () => {
    try {
      setLoadingSendCode(true);
      let formData ={
        local: true,
        type: "login",
        authenticated_telephone_otp: 1,
        identifier: identifierRef.current.value,
        redirect: false,
      }
      console.log(formData);
      const source = axios.CancelToken.source();
      const response = await _axios.post(buildLink("send_OTP", undefined, window.innerWidth), formData, {
        cancelToken: source.token,
      });

      console.log(response.data);
      
      
        if (response.data.success) {
          setCounterOtp(30);
          handleStartCountOtp();
          
        } else if (!response.data.success && response.data.errors)  {
        
          handleSetError(response.errors[0].errorMsg);
          setPhoneNotExist(identifierRef.current.value);
        }
        setLoadingSendCode(false);
      } catch (err) {
        handleSetError(err.message);
      }
    };

  // Check login
  function checkLogin() {
    dispatch({ type: "setLoading", payload: true });
    _axios
      .get(buildLink("login", undefined, window.innerWidth))
      .then((response) => {
        const data = response.data;

        dispatch({ type: "setShowOver", payload: false });
        if (data.customer_id > 0) {
          dispatch({ type: "setLoged", payload: true });
          dispatch({ type: "setUsername", payload: data.username });
        } else {
          dispatch({ type: "setLoged", payload: false });
        }
        dispatch({ type: "setLoading", payload: false });
      });
  }

  return (
    <div className="checkout-viewport bg-dgrey10">
      <div className="auth-register-mobile md:hidden flex justify-around text-center mt-1.5 items-center text-d24 font-semibold">
        <div
          className={`w-1/2  text-dbasenavy bg-grey-gradient ${"font-semibold bg-dwhite1 bg-none border-b-8 border-dblue3"}   text-d20 py-5 flex justify-center relative text-center h-16`}
        >
          Login
        </div>
        <div
          className={`w-1/2 ${"font-normal border-b-2 border-dgrey3"} text-dbasenavy bg-grey-gradient  text-d20 py-5 flex justify-center relative text-center h-16`}
          onClick={() => navigate("/signup")}
        >
          Sign up
        </div>
      </div>
      <div className="md:container">
        <div className="auth-register flex flex-col md:flex-row justify-center -mt-4 md:mt-0 mb-5 pt-5">
          <div
            className={`w-full md:w-1/2 relative px-5 md:block `}
            data-id="login"
          >
            <div className="auth-register__wrapper  ">
            <h2
                        className={` ${
                          error ? "" : "hidden"
                        } py-2 text-dred4 bg-red-200 flex text-center  overflow-hidden transition-all w-full bg-dbase  gap-3 bg-opacity-20 text-dbase`}
                      >
                        <div className="flex items-start justify-start">
                       
                        <span className="flex items-center gap-2 text-red-600 text-sm"> <IoWarning style={{width: '20px', height:'20px'}} className=" my-auto" />
                          {error}
                         </span>
                        </div>
                        
                      </h2>
              <div className="authentication-login lg:py-2.5 md:px-5 md:py-7 lg:px-12 md:bg-dwhite1 min-h-full mb-12">
                
                <div className="hidden md:block auth_title text-d27 text-center text-dborderblack2 m-2.5">
                  Login
                </div>
                <div>
                  {type === "emailLogin" ? (
                    <form onSubmit={(e) => loginByEmailOrTelephone(e, "email")}>
                      <input type="hidden" name="" />
                      <div className="mt-2 relative">
                        <div className="flex justify-between items-center mb-1 text-d12 md:text-d14 lg:text-d16">
                          <label className="">Email Address</label>
                          <button
                            onClick={() => setType("phoneLogin")}
                            className="hover:text-dblack hover:underline text-sm capitalize w-1/2 text-right"
                          >
                            Log In with phone number
                          </button>
                        </div>
                        <input
                          type="email"
                          name="email"
                          ref={identifierRef}
                          required
                          placeholder="E-Mail Address"
                          className="border border-dgrey3 font-d16 text-dborderblack2 h-14 mb-2.5 px-5 w-full outline-none focus:border focus:border-dblack2"
                        />
                      </div>
                      <div className={`error-email text-dred4 hidden`}></div>
                      <div className="mt-1 relative">
                        <div className="flex justify-start mb-1">
                          <label>Password </label>
                        </div>
                        <div className="relative w-full mb-2.5">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            ref={authRef}
                            minLength={6}
                            required
                            placeholder="Your password"
                            className="border border-dgrey3 font-d16 text-dborderblack2 h-14 px-5 w-full outline-none focus:border focus:border-dblack2"
                          />

                          <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute top-1/2 right-5 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                          >
                            {showPassword ? (
                              <IoEyeOff size={20} />
                            ) : (
                              <IoEye size={20} />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className={`error-password text-dred4 hidden`}></div>
                     
                      <input type="hidden" name="" />
                      <div className="clear hidden overflow-hidden "></div>
                      <div className="flex justify-between items-baseline -mt-2 -mb-3 relative ">
                        <label
                          htmlFor=""
                          className="flex text-justify text-d12 my-4 items-center"
                        >
                          <input
                            type="checkbox"
                            name="remember-me"
                            className="h-4 w-4 border-2 border-dbordergrey rounded-sm relative mr-2.5"
                          />
                          <p className="text-sm">Remember me</p>
                        </label>
                        <Link
                          to={"/forgotmypassword"}
                          className="block mt-2 mb-4 text-sm text-right"
                        >
                          I forgot my password
                        </Link>
                      </div>
                      <div className="mt-3.5 relative">
                        <button
                          type="submit"
                          className="text-d16 bg-dborderblack2 h-14 block text-center 
                                        leading-3 w-full bg-clip-padding text-dwhite1 uppercase z-10"
                        >
                          LOGIN
                        </button>
                      </div>
                      <div className="mt-3.5 relative"></div>
                    </form>
                  ) : type === "phoneLogin" ? (
                    <form
                      onSubmit={(e) => loginByEmailOrTelephone(e, "telephone")}
                    >
                      <input type="hidden" name="" />
                      <div className="mt-2 relative">
                        <div className="flex justify-between items-center mb-1">
                          <label>Phone Number</label>
                          <button
                            onClick={() => setType("emailLogin")}
                            className="hover:text-dblack hover:underline text-sm capitalize"
                          >
                             Log In with email
                          </button>
                        </div>
                        <div className="border flex items-center gap-3 border-dgrey3 font-d16 text-dborderblack2 h-14 mb-2.5  w-full outline-none focus:border focus:border-dblack2">
                          <div className="px-3 border-r whitespace-nowrap xl:w-[20%] border-dlabelColor">
                            LBP +961{" "}
                          </div>
                          <input
                            type="number"
                            defaultValue={phoneNotExist}
                            name="email"
                            ref={identifierRef}
                            required
                            placeholder="Enter Your Phone number"
                            className="  font-d16   w-[60%] outline-none  "
                          />
                        </div>
                      </div>
                      <div className={`error-email text-dred4 hidden`}></div>
                      <div className="mt-1 relative">
                        <div className="flex justify-start mb-1">
                          <label>Password </label>
                        </div>
                        <div className="relative w-full mb-2.5">
                          <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            ref={authRef}
                            minLength={6}
                            required
                            placeholder="Your password"
                            className="border border-dgrey3 font-d16 text-dborderblack2 h-14 px-5 w-full outline-none focus:border focus:border-dblack2"
                          />

                          <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute top-1/2 right-5 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 focus:outline-none"
                          >
                            {showPassword ? (
                              <IoEyeOff size={20} />
                            ) : (
                              <IoEye size={20} />
                            )}
                          </button>
                        </div>
                      </div>
                      <div className={`error-password text-dred4 hidden`}></div>
                      
                      <input type="hidden" name="" />
                      <div className="clear hidden overflow-hidden "></div>
                      <label
                        htmlFor=""
                        className="flex text-justify text-d12 my-4 items-center"
                      >
                        <input
                          type="checkbox"
                          name="remember-me"
                          className="h-4 w-4 border-2 border-dbordergrey rounded-sm relative mr-2.5"
                        />
                        <p className="text-sm">Remember me</p>
                      </label>
                      <div className="flex justify-between items-baseline -mt-2 -mb-3 relative ">
                        <Link
                          to={"/forgotmypassword"}
                          className="block mt-2 mb-4 text-sm text-right"
                        >
                          I forgot my password
                        </Link>
                        <button
                          type="button"
                          className=" hover:text-dblack hover:underline "
                          onClick={() => setType("loginPhoneOtp")}
                        >
                          Log in with code
                        </button>
                      </div>
                      <div className="mt-3.5 relative">
                        <button
                          type="submit"
                          className="text-d16 bg-dborderblack2 h-14 block text-center 
                                      leading-3 w-full bg-clip-padding text-dwhite1 uppercase z-10"
                        >
                          LOGIN
                        </button>
                      </div>
                      <div className="mt-3.5 relative"></div>
                    </form>
                  ) : type === "loginPhoneOtp" ? (
                    <form
                      onSubmit={(e) => {
                        loginByEmailOrTelephone(e, "loginPhoneOtp")

                      }}
                    >
                      <input type="hidden" name="" />
                      <div className="mt-2 relative">
                        <div className="flex justify-between items-center mb-1">
                          <label>Phone Number</label>
                          <button
                            onClick={() => setType("emailLogin")}
                            className="hover:text-dblack hover:underline text-sm capitalize"
                          >
                            Log In with email
                          </button>
                        </div>
                        <div className="border flex items-center gap-3 border-dgrey3 font-d16 text-dborderblack2 h-14 mb-2.5  w-full outline-none focus:border focus:border-dblack2">
                          <div className="px-3 border-r whitespace-nowrap xl:w-[20%] border-dlabelColor">
                            LBP +961{" "}
                          </div>
                          <input
                            type="number"
                            name="email"
                            ref={identifierRef}
                            required
                            placeholder="Enter Your Phone number"
                            className="  font-d16   w-[60%] outline-none  "
                          />
                        </div>
                      </div>
                      <div className={`error-email text-dred4 hidden`}></div>
                      <div className="  border-opacity-40 border border-[#d5d5d5]  flex flex-row w-full bg-[#d5d5d5] bg-opacity-25   rounded-md ">
                    <input
                      ref={authRef}
                      placeholder="Enter 6-digit Code"
                      className="py-3 custom-cursor-color w-full h-full  px-4  bg-transparent outline-none border-none"
                    ></input>
                    <button
                      disabled={counterOtp > 0 || LoadingSendCode}
                      onClick={() => sendOtpForLoginTelephon()}
                      type="button"
                      className={` hover:bg-borderbottom px-3 py-2   border border-[#d5d5d5] shadow-dgrey1 w-[70%]  ${
                        counterOtp > 0 && "cursor-not-allowed"
                      }  rounded-e-md`}
                    >
                      {" "}
                      {LoadingSendCode ? (
                        <div className="w-6 flex mx-auto justify-center relative">
                          <NewLoader />
                        </div>
                      ) : counterOtp > 0 ? (
                        <span className="text-sm">
                          Wait{" "}
                          <span className="text-dblue font-bold">
                            {counterOtp}
                          </span>
                          s
                        </span>
                      ) : (
                        <span>Send Code</span>
                      )}
                    </button>
                  </div>
                      <div className={`error-password text-dred4 hidden`}></div>
                      <input type="hidden" name="" />
                      <div className="clear hidden overflow-hidden "></div>
                      <label
                        htmlFor=""
                        className="flex text-justify text-d12 my-4 items-center"
                      >
                        <input
                          type="checkbox"
                          name="remember-me"
                          className="h-4 w-4 border-2 border-dbordergrey rounded-sm relative mr-2.5"
                        />
                        <p className="text-sm">Remember me</p>
                      </label>
                      <div className="flex justify-between items-baseline -mt-2 -mb-3 relative ">
                        <Link
                          to={"/forgotmypassword"}
                          className="block mt-2 mb-4 text-sm text-right"
                        >
                          I forgot my password
                        </Link>
                        <button
                          type="button"
                          className=" hover:text-dblack hover:underline "
                          onClick={() => setType("phoneLogin")}
                        >
                          Log in with password
                        </button>
                      </div>
                      <div className="mt-3.5 relative">
                        <button
                          type="submit"
                          className="text-d16 bg-dborderblack2 h-14 block text-center 
                                    leading-3 w-full bg-clip-padding text-dwhite1 uppercase z-10"
                        >
                          LOGIN
                        </button>
                      </div>
                      <div className="mt-3.5 relative"></div>
                    </form>
                  ) : null}
                </div>
                <div className="border-t w-full border-dplaceHolder py-3 md:py-6">
                  <h2 className="text-sm text-dlabelColor md:text-lg">
                    Don't you have an account?{" "}
                    <button
                      onClick={() => navigate("/signup")}
                      className="font-bold text-dborderblack2"
                    >
                      Sign up
                    </button>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="login-register-secure-shopping flex justify-center items-center mt-12  bg-dgrey10">
          <div className="flex items-center">
            {/* <img
            src="https://akn-eh.b-cdn.net/static_omnishop/eh590/assets/img/insurance.png"
            alt=""
            className="mb-8"
          /> */}
            <GrSecure className="mb-8" />
            <div className="text-d18 text-dborderblack2 ml-2.5 font-semibold mb-8">
              Secure shopping
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
