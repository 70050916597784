import React from "react";
import { useEffect, useState, useContext, useRef } from "react";
import { AccountContext } from "../contexts/AccountContext";
import buildLink, { path } from "../urls";
import _axios from "../axios";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { GrSecure } from "react-icons/gr";
import { FaEye, FaEyeSlash } from "react-icons/fa";

function SignUp() {
  const [state, dispatch] = useContext(AccountContext);
  const [signupError, setSignupError] = useState("");
  const [showSignupError, setShowSignupError] = useState(false);
  const [signupLoading, setSignupLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [logindiv, setLogindiv] = useState(false);
  const [openEyesPassword, setOpenEyesPassword] = useState(false);
  const loginEmail = useRef("");
  const loginPassword = useRef("");
  const signupEmail = useRef("");
  const signupPassword = useRef("");
  const signupFirst = useRef("");
  const signupLast = useRef("");
  const signuptelehone = useRef("");
  const navigate = useNavigate();
  const width = window.innerWidth;
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if(state.loged){
      navigate('/');
    }
  }, [state.loged]);

  //sign up
  function signup(e) {
    e.preventDefault();
    setSignupLoading(true);
    const email = signupEmail.current.value;
    const password = signupPassword.current.value;
    const confirm = signupPassword.current.value;
    const firstname = signupFirst.current.value;
    const lastname = signupLast.current.value;
    const telephone = signuptelehone.current.value;
    const obj = JSON.stringify({
      email,
      password,
      firstname,
      lastname,
      confirm,
      telephone,
    });
    _axios.post(buildLink("register"), obj).then((response) => {
      const data = response.data;
      if (!data.success) {
        setShowSignupError(true);
        setSignupError(data?.errors[0]?.errorMsg);
        console.log(data?.errors[0]?.errorMsg);
      } else {
        checkLogin();
        navigate("/");
      }
      setSignupLoading(false);
    });
  }

  // Login


  const handleClickEyeButton = () => {
    setOpenEyesPassword((prevState) => !prevState); // toggle the visibility of the password
  };
  // Check login
  function checkLogin() {
    dispatch({ type: "setLoading", payload: true });
    _axios
      .get(buildLink("login", undefined, window.innerWidth))
      .then((response) => {
        const data = response.data;

        dispatch({ type: "setShowOver", payload: false });
        if (data.customer_id > 0) {
          dispatch({ type: "setLoged", payload: true });
          dispatch({ type: "setUsername", payload: data.username });
          navigate("/");
          window.location.reload();
        } else {
          dispatch({ type: "setLoged", payload: false });
        }
        dispatch({ type: "setLoading", payload: false });
      });
  }

  return (
    <div className="checkout-viewport bg-dgrey10">
      <div className="auth-register-mobile md:hidden flex justify-around text-center mt-1.5 items-center text-d24 font-semibold">
        <div
          className={`w-1/2  text-dbasenavy bg-grey-gradient ${
  
              "font-normal border-b-2 border-dgrey3"
          }   text-d20 py-5 flex justify-center relative text-center h-16`}
          onClick={() => navigate("/login")}
        >
          Login
        </div>
        <div
          className={`w-1/2 ${
          
               "font-semibold bg-dwhite1 bg-none border-b-8 border-dblue3"
          } text-dbasenavy bg-grey-gradient  text-d20 py-5 flex justify-center relative text-center h-16`}
        >
          Sign up
        </div>
      </div>
      <div className="md:container">
        <div className="auth-register flex flex-col md:flex-row justify-center -mt-4 md:mt-0 mb-5 pt-5">
       
          <div
            className={`w-full md:w-1/2 relative px-5 md:block `}
            data-id="register"
          >
            <div className="auth-register__wrapper py-2.5 px-5 md:py-7 md:px-12 md:bg-dwhite1 min-h-full mb-12">
              <div className="authentication-register">
                <div className="auth-box ">
                  <div className="hidden md:block auth__form--title text-d27 text-center text-dborderblack2 m-2.5">
                    Sign up
                  </div>
                  <div>
                    <form onSubmit={(e) => signup(e)}>
                      <input type="hidden" name="" />
                      <div
                        className="mt-3.5 relative flex items-center justify-between 
                      "
                      >
                        
                        <div className="w-488">
                            <div className="flex justify-start mb-1"><label>First Name</label></div>
                          <input
                            type="text"
                            name=""
                            required
                            ref={signupFirst}
                            className="border w-full border-dgrey3 text-d16 text-dborderblack2 h-14 mb-2.5 px-5 outline-none focus:border focus:border-dblack2"
                            placeholder="First name"
                          />
                          <div className={`error-name text-dred4 hidden`}></div>
                        </div>
                        <div className="w-488">
                        <div className="flex justify-start mb-1"><label>Last Name</label></div>
                          <input
                            type="text"
                            name=""
                            required
                            ref={signupLast}
                            className="border w-full  border-dgrey3 text-d16 text-dborderblack2 h-14 mb-2.5 px-5 outline-none focus:border focus:border-dblack2"
                            placeholder="Last name"
                          />
                          <div className={`error-name text-dred4 hidden`}></div>
                        </div>
                      </div>
                      <div className="mt-1 relative w-full">
                      <div className="flex justify-start mb-1"><label>Email Address</label></div>
                        <input
                          type="email"
                          name=""
                          required
                          ref={signupEmail}
                          className="border border-dgrey3 text-d16 w-full text-dborderblack2 h-14 mb-2.5 px-5 outline-none focus:border focus:border-dblack2"
                          placeholder="E-mail address"
                        />
                        <div className={`error-email text-dred4 hidden`}></div>
                      </div>
                      <div className="mt-1 relative">
                      <div className="flex justify-start mb-1"><label>Phone Number</label></div>
                        <input
                        ref={signuptelehone}
                          type="text"
                          name=""
                          className="border border-dgrey3 text-d16 w-full text-dborderblack2 h-14 mb-2.5 px-5 outline-none focus:border focus:border-dblack2"
                          placeholder="Phone number"
                        />
                        <div className={`error-phone text-dred4 hidden`}></div>
                      </div>
                      <div className="mt-1 mb-1 relative">
                      <div className="flex justify-start mb-1"><label>Password</label></div>
                       <button
                        onClick={handleClickEyeButton}
                        type="button"
                       className="absolute right-3 mt-5"
                    >
                        {openEyesPassword ? <FaEye /> : <FaEyeSlash />}
                    </button>
                    
                    <input
                        type={openEyesPassword ? "text" : "password"} // change input type based on state
                        name="password"
                        minLength={6}
                        required
                        ref={signupPassword}
                        className="border border-dgrey3 text-d16 w-full text-dborderblack2 h-14 mb-2.5 px-5 outline-none focus:border focus:border-dblack2"
                        placeholder="Password"
                    />
                      </div>
                      <div className="consent-agreement -mb-1">
                        <div
                          className="flex items-center text-d12 text-justify mb-2.5"
                          style={{ color: "#626262" }}
                        >
                          <input
                            type="checkbox"
                            name="email_allowed"
                            className="h-6 w-8  border-2 border-dbordergrey rounded-sm relative mr-2.5"
                          />
                          <input
                            type="checkbox"
                            name="sms_allowed"
                            className="h-6 w-8  border-2 border-dbordergrey rounded-sm relative mr-2.5 hidden"
                          />
                          <div className="">
                            <p>
                              I would like to receive SMS and electronic
                              messages within the scope of the{" "}
                              <span className="underline">
                                Illumination Text
                              </span>{" "}
                              to be informed about the campaigns
                            </p>
                          </div>
                        </div>
                        <div
                          className="flex items-center text-d12 text-justify mb-2.5"
                          style={{ color: "#626262" }}
                        >
                          <input
                            type="checkbox"
                            name="confirm"
                            className="h-6 w-8  border-2 border-dbordergrey rounded-sm relative mr-2.5 "
                          />
                          <div className="">
                            <p>
                              I have read and understood the{" "}
                              <span className="underline">
                                Illumination Text.
                              </span>{" "}
                              I accept the{" "}
                              <span className="underline">
                                Membership Agreement
                              </span>{" "}
                              within the scope of the Clarification Text.
                            </p>
                          </div>
                        </div>
                        <div
                          className="flex items-center text-d12 text-justify mb-2.5"
                          style={{ color: "#626262" }}
                        >
                          <input
                            type="checkbox"
                            name="tom_pay_allowed"
                            className="h-6 w-8  border-2 border-dbordergrey rounded-sm relative mr-2.5 "
                          />
                          <div className="mb-1">
                            <p>
                              I consent to the sending of electronic commercial
                              messages by TOM Pay within the scope of the{" "}
                              <span className="underline">
                                Clarification Text.
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="sign-up  mt-3.5 relative">
                        <button
                          type="submit"
                          className="text-d16 bg-dborderblack2 h-14 block text-center 
                                        leading-3 w-full bg-clip-padding text-dwhite1 uppercase "
                        >
                          {" "}
                          {signupLoading ? (
                            <span>LOADING</span>
                          ) : (
                            <span>SIGN UP</span>
                          )}
                        </button>
                        {signupError && (
                          <div
                            className={`error-password text-dred4 mt-2 text-sm`}
                          >
                            {signupError}
                          </div>
                        )}
                      </div>
                      <div className="border-t w-full border-dplaceHolder py-6">
                        <Link to="/login"></Link>
                  <h2 className="text-sm text-dlabelColor text-lg">
                    Already have an account ?{" "}
                    <button
                      onClick={() => navigate("/login")}
                      className="font-bold text-dborderblack2 "
                    >
                      Log in
                    </button>
                  </h2>
                </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="login-register-secure-shopping flex justify-center items-center mt-12  bg-dgrey10">
          <div className="flex items-center">
            {/* <img
            src="https://akn-eh.b-cdn.net/static_omnishop/eh590/assets/img/insurance.png"
            alt=""
            className="mb-8"
          /> */}
            <GrSecure className="mb-8" />
            <div className="text-d18 text-dborderblack2 ml-2.5 font-semibold mb-8">
              Secure shopping
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
