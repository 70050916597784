import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import Category from "./pages/Category";
import Product from "./pages/Product";
import Cart from "./pages/Cart";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ForgotMyPassword from "./pages/ForgotMyPassword";
import Checkout from "./pages/Checkout";
import HeaderCampaigns from "./components/HeaderCampaigns";
import { AccountContext, AccountProvider } from "./contexts/AccountContext";
import { CartContext, CartProvider } from "./contexts/CartContext";
import { WishlistContext, WishlistProvider } from "./contexts/WishlistContext";
import { InformationProvider } from "./contexts/InformationContext";
import Login from "./pages/Login";
import Account from "./pages/Account";
import Orders from "./pages/Orders";
import Profile from "./pages/Profile";
import Search from "./pages/Search";
import OrderDetails from "./pages/OrderDetails";
import ChangeEmail from "./pages/ChangeEmail";
import ChangePassword from "./pages/ChangePassword";
import Addresses from "./pages/Addresses";
import Information from "./pages/Information";
import ProductPreview from "./pages/ProductPreview";

import Success from "./pages/Success";
import Wishlist from "./pages/Wishlist";
import Latest from "./pages/Latest";
import NotFound from "./pages/NotFound";
import Contact from "./pages/Contact";

import PosOrders from "./pages/PosOrders";
import Pos from "./pages/Pos";
import PosPrinttest from "./components/Posprint";
import StoreLocator from "./pages/StoreLocator";
import PosPrint from "./components/Posprint";
import { useContext, useEffect, useState } from "react";
import { AiFillHeart } from "react-icons/ai";
import useGTMPageView from "./useGTMpageView";
import SignUp from "./pages/SignUp";
import Rewards from "./pages/Rewards";
import MembershipDetails from "./pages/MembershipDetails";


function App() {
  const width = window.innerWidth;
  const [statew,dispatchw] = useContext(WishlistContext);
  const [stateCart,dispatchCart] = useContext(CartContext)

  var prevScroll = 200;
  let header ;
  let bottombar ;


  const navigate = useNavigate();
  const router = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
setLoading(true);
setTimeout(() => {
  setLoading(false)
}, 500);
  }, [navigate]);

//   useEffect(()=>{
//     header = document.getElementById("headersticky")
//  },[])

//  useEffect(() => {

//   if( header){

   
//    const handleScroll = () => {
//      const scrollTop = window.pageYOffset;
    
//      if(scrollTop<200){
//         header.classList.remove("hide")
//      }else{
//        if(scrollTop> prevScroll){
//          //  console.log(`prev${prevScroll}`);
      
//            header.classList.add("hide")
        
         
//          }else if(scrollTop< prevScroll) {
//            header.classList.remove("hide")
//          }
//        prevScroll = scrollTop
//      }

//    };
//    window.addEventListener('scroll', handleScroll);
//    return () => {
//      window.removeEventListener('scroll', handleScroll);
//    };
//  }
//  }, [ header]);
const location = useLocation()
useEffect(()=>{
  dispatchCart({type:"setShowMenu",payload:false})
},[location.pathname])

useGTMPageView();



  return (
    <>
    <div
      className={`App w-full ${
        window.location.href.split("/")[3] === "pos" && "fixed"
      }`}
    >
     {loading && <div className=" fixed z-[9999] w-full h-full inset-0 bg-black bg-opacity-20 "></div>}
     { window.location.href.split("/")[3] !== "posprint" && <Header />}

              {width > 650 && <HeaderCampaigns />}
              <Routes>
                {window.location.href.split("/")[3] === "storeone" ? (
                  <>
                    <Route path="/storeone" element={<Home />} exact />
                    <Route path="/storeone/category" element={<Category />} />
                    <Route
                      path={"/storeone/:name/c=:id"}
                      element={<Category />}
                      exact
                    />
                    <Route
                      path={"/storeone/:name/c=:id/all"}
                      element={<Category />}
                      exact
                    />
                    <Route
                      path={"/storeone/:name/s=:id"}
                      element={<Category />}
                      exact
                    />
                    <Route
                      path={"/storeone/:name/m=:id"}
                      element={<Category />}
                      exact
                    />
                    <Route path="/storeone/cart" element={<Cart />} />
                    <Route path="/storeone/checkout" element={<Checkout />} />
                    <Route path="/storeone/login" element={<Login />} />
                    <Route path="/storeone/signup" element={<SignUp />} />
                    <Route
                      path="/storeone/forgotmypassword"
                      element={<ForgotMyPassword />}
                    />
                    <Route
                      path="/storeone/product/:id"
                      element={<Product />}
                    />
                    <Route
                      path="/storeone/:name/p=:id"
                      element={<Product />}
                      exact
                    />

                    <Route path="/storeone/account" element={<Account />} />
                    <Route
                      path="/storeone/account/orders"
                      element={<Orders />}
                    />
                    <Route
                      path="/storeone/account/profile"
                      element={<Profile />}
                    />
                    <Route
                      path="/storeone/account/addresses"
                      element={<Addresses />}
                    />
                    <Route
                      path="/storeone/storeLocator"
                      element={<StoreLocator />}
                    />
                    <Route
                      path="/storeone/information/:id"
                      element={<Information />}
                    />
                    <Route path="/storeone/contact" element={<Contact />} />

                    <Route path="/storeone/search" element={<Search />} />
                    <Route path="/storeone/latest" element={<Latest />} />
                    <Route
                      path="/storeone/posOrders"
                      element={<posOrders />}
                    />

                    <Route path="/storeone/pos" element={<Pos />} />

                    <Route
                      path="/storeone/account/change-email"
                      element={<ChangeEmail />}
                    />
                    <Route
                      path="/storeone/account/change-password"
                      element={<ChangePassword />}
                    />
                    <Route
                      path="/storeone/account/order-details"
                      element={<OrderDetails />}
                    />
                    <Route
                      path="/storeone/account/wishlist"
                      element={<Wishlist />}
                    />
                    <Route path="/storeone/success" element={<Success />} />
                    <Route
                      path="/storeone/:name"
                      element={<NotFound />}
                      exact
                    />
                  </>
                ) : window.location.href.split("/")[3] === "storetwo" ? (
                  <>
                    <Route path="/storetwo" element={<Home />}  />
                    <Route path="/storetwo/category" element={<Category />} />
                    <Route
                      path={"/storetwo/:name/c=:id"}
                      element={<Category />}
                      exact
                    />
                    <Route
                      path={"/storetwo/:name/c=:id/all"}
                      element={<Category />}
                      exact
                    />
                    <Route
                      path={"/storetwo/:name/s=:id"}
                      element={<Category />}
                      exact
                    />
                    <Route
                      path={"/storetwo/:name/m=:id"}
                      element={<Category />}
                      exact
                    />
                    <Route path="/storetwo/cart" element={<Cart />} />
                    <Route path="/storetwo/checkout" element={<Checkout />} />
                    <Route path="/storetwo/login" element={<Login />} />
                    <Route path="/storetwo/signup" element={<SignUp />} />
                    <Route
                      path="/storetwo/forgotmypassword"
                      element={<ForgotMyPassword />}
                    />
                    <Route
                      path="/storetwo/product/:id"
                      element={<Product />}
                    />
                    <Route
                      path="/storetwo/:name/p=:id"
                      element={<Product />}
                      exact
                    />

                    <Route path="/storetwo/account" element={<Account />} />
                    <Route
                      path="/storetwo/account/orders"
                      element={<Orders />}
                    />
                    <Route
                      path="/storetwo/account/profile"
                      element={<Profile />}
                    />
                    <Route
                      path="/storetwo/account/addresses"
                      element={<Addresses />}
                    />
                    <Route
                      path="/storetwo/storeLocator"
                      element={<StoreLocator />}
                    />
                    <Route
                      path="/storetwo/information/:id"
                      element={<Information />}
                    />
                    <Route path="/storetwo/contact" element={<Contact />} />

                    <Route path="/storetwo/search" element={<Search />} />
                    <Route path="/storetwo/latest" element={<Latest />} />
                    <Route
                      path="/storetwo/posOrders"
                      element={<posOrders />}
                    />

                    <Route path="/storetwo/pos" element={<Pos />} />

                    <Route
                      path="/storetwo/account/change-email"
                      element={<ChangeEmail />}
                    />
                    <Route
                      path="/storetwo/account/change-password"
                      element={<ChangePassword />}
                    />
                    <Route
                      path="/storetwo/account/order-details"
                      element={<OrderDetails />}
                    />
                    <Route
                      path="/storetwo/account/wishlist"
                      element={<Wishlist />}
                    />
                    <Route path="/storetwo/success" element={<Success />} />
                    <Route
                      path="/storetwo/:name"
                      element={<NotFound />}
                      exact
                    />
                  </>
                ) 
                : window.location.href.split("/")[3] === "storezahle" ? (
                  <>
                    <Route path="/storezahle" element={<Home />}  />
                    <Route path="/storezahle/category" element={<Category />} />
                    <Route
                      path={"/storezahle/:name/c=:id"}
                      element={<Category />}
                      exact
                    />
                    <Route
                      path={"/storezahle/:name/c=:id/all"}
                      element={<Category />}
                      exact
                    />
                    <Route
                      path={"/storezahle/:name/s=:id"}
                      element={<Category />}
                      exact
                    />
                    <Route
                      path={"/storezahle/:name/m=:id"}
                      element={<Category />}
                      exact
                    />
                    <Route path="/storezahle/cart" element={<Cart />} />
                    <Route path="/storezahle/checkout" element={<Checkout />} />
                    <Route path="/storezahle/login" element={<Login />} />
                    <Route
                      path="/storezahle/forgotmypassword"
                      element={<ForgotMyPassword />}
                    />
                    <Route
                      path="/storezahle/product/:id"
                      element={<Product />}
                    />
                    <Route
                      path="/storezahle/:name/p=:id"
                      element={<Product />}
                      exact
                    />

                    <Route path="/storezahle/account" element={<Account />} />
                    <Route
                      path="/storezahle/account/orders"
                      element={<Orders />}
                    />
                    <Route
                      path="/storezahle/account/profile"
                      element={<Profile />}
                    />
                    <Route
                      path="/storezahle/account/addresses"
                      element={<Addresses />}
                    />
                    <Route
                      path="/storezahle/storeLocator"
                      element={<StoreLocator />}
                    />
                    <Route
                      path="/storezahle/information/:id"
                      element={<Information />}
                    />
                    <Route path="/storezahle/contact" element={<Contact />} />

                    <Route path="/storezahle/search" element={<Search />} />
                    <Route path="/storezahle/latest" element={<Latest />} />
                    <Route
                      path="/storezahle/posOrders"
                      element={<posOrders />}
                    />

                    <Route path="/storezahle/pos" element={<Pos />} />

                    <Route
                      path="/storezahle/account/change-email"
                      element={<ChangeEmail />}
                    />
                    <Route
                      path="/storezahle/account/change-password"
                      element={<ChangePassword />}
                    />
                    <Route
                      path="/storezahle/account/order-details"
                      element={<OrderDetails />}
                    />
                    <Route
                      path="/storezahle/account/wishlist"
                      element={<Wishlist />}
                    />
                    <Route path="/storezahle/success" element={<Success />} />
                    <Route
                      path="/storezahle/:name"
                      element={<NotFound />}
                      exact
                    />
                  </>
                )
                
                : (
                  <>
                    <Route path="/" element={<Home />} />
                    <Route path="category/c=:id" element={<Category />} />
                    <Route path={"/:name/c=:id"} element={<Category />} exact />
                    <Route
                      path={"/:name/c=:id/all"}
                      element={<Category />}
                      exact
                    />
                    <Route path={"/:name/s=:id"} element={<Category />} exact />
                    <Route path={"/:name/m=:id"} element={<Category />} exact />
                    <Route path="cart" element={<Cart />} />
                    <Route path="checkout" element={<Checkout />} />
                    <Route path="memberShipDetails" element={<MembershipDetails />} />
                    <Route path="login" element={<Login />} />
                    <Route path="signup" element={<SignUp />} />
                    <Route path="rewards" element={<Rewards />} />
                    <Route
                      path="forgotmypassword"
                      element={<ForgotMyPassword />}
                    />
                    <Route path="product/:id" element={<Product />} />
                    <Route path="/:name/p=:id" element={<Product />} exact />

                    <Route path="account" element={<Account />} />
                    <Route path="account/orders" element={<Orders />} />
                    <Route path="account/profile" element={<Profile />} />
                    <Route path="account/addresses" element={<Addresses />} />
                    <Route path="/storeLocator" element={<StoreLocator />} />
                    <Route path="/information/:id" element={<Information />} />
                    <Route path="/posprint/:id" element={<PosPrint />} />
                    <Route path="/contact" element={<Contact />} />

                    <Route path="search" element={<Search />} />
                    <Route path="latest" element={<Latest />} />
                    <Route
                      path="account/change-email"
                      element={<ChangeEmail />}
                    />
                    <Route
                      path="account/change-password"
                      element={<ChangePassword />}
                    />
                    <Route
                      path="account/order-details"
                      element={<OrderDetails />}
                    />
                    <Route path="/posOrders" element={<PosOrders />} />

                    <Route path="/pos" element={<Pos />} />
                    <Route path="account/wishlist" element={<Wishlist />} />
                    <Route path="success" element={<Success />} />
                    <Route path="/:name" element={<NotFound />} exact />
                  </>
                )}
              </Routes>
              {window.location.href.split("/")[3] !== "posprint" &&
                window.location.href.split("/")[3] !== "pos" &&
                window.location.href.split("/")[3] !== "orders" && <Footer />}
          
    </div>




    {statew.popUpW && (
        <div
          className="fixed bg-dblackOverlay top-0 left-0   w-full h-full z-40 overflow-hidden"
          onClick={() => {
            dispatchw({type:"setPopupW",payload:false})
          }}
        >
          <div className="text-center absolute w-full h-full left-0 top-0 px-2 box-border">
            <div
              className="cursor-auto w-80 md:w-96 bg-clip-padding py-2.5 px-7 my-10 mx-auto bg-dwhite1 box-border relative top-1/4 inline-block align-middle text-left z-50 rounded-lg"
              style={{ minWidth: "290px" }}
            >
              <div className="block relative pt-6 md:pt-8 h-72 text-center">
                <div className="icon-wrapper text-center mb-9 flex justify-center">
                  <AiFillHeart className="text-black2 w-14 h-14" />
                </div>
                <div className="text-d15 font-bold text-center text-dblack2">
                  FAVORITE PRODUCT
                </div>
                <p className="text-d16 mt-2.5 text-center text-dblack2">
                  You have added the product to your favorite list. You can view
                  your entire list of favorite items by clicking the heart in
                  the menu.
                </p>
                <button
                  className="text-dblack2 font-bold h-14 text-d16 w-full underline"
                  onClick={() => {
                    dispatchw({type:"setPopupW",payload:false})
                  }}
                >
                  close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
