import React, { useEffect, useState } from "react";
import Loyality from "../assets/images/loyatlity.jpg";
import RewardStep from "../components/RewardStep";
import _axios from "../axios";
import buildLink from "../urls";

function Rewards() {

  const [pointsRange, setPointsRange] = useState([]);
  const [information, setInformation] = useState();
  const [gainingPoints, setGainingPoints] =useState([]);


  useEffect(() => {
      _axios
        .get(buildLink("pointsInfo", undefined, window.innerWidth))
        .then((response) => {
          try {
            let point_ranges_data = response?.data?.data?.point_ranges;
            let point_information = response?.data?.data?.information;
            let gaining_points = response?.data?.data?.gaining_points;
            if(response?.data?.success){
                setPointsRange(point_ranges_data)
                setInformation(point_information)
                setGainingPoints(gaining_points)
            }
          } catch (error) {}
        });
    }
  , []);



  console.log(pointsRange);
  

  let fakeData = [
    {
      title: "Create an account",
      text: "To get started, join now. You can also Join in the app to get access to the full range of Starbucks® Rewards benefits.",
    },
    {
      title: "Order and pay how you’d like",
      text: "Use cash, credit/debit card or save some time and pay right through the app. You’ll collect Stars all ways. Learn how",
    },
    {
      title: "Earn Stars, get Rewards",
      text: "As you earn Stars, you can redeem them for Rewards—like free food, drinks, and more. Start redeeming with as little as 25 Stars!",
    },
  ];



  return (
    <div className="min-h-screen">
      <div className="w-full h-fit ">
        <div className="pl-24 py-4 text-white bg-dborderblack1 font-bold flex justify-start">
          CUSTOMER REWARDS
        </div>

        <div className="flex flex-col md:flex-row items-center gap-8 py-12 px-[5vh] md:px-[7vh] lg:px-[10vh] border">
          <div className="md:w-[40%]">
            <img
            style={{ maxHeight: "460px", width:'100%' }}
              src={Loyality}
              alt="Rewards"
              className="w-full max-w-sm mx-auto"
            />
          </div>
          <div className="md:w-[60%]">
            <div className="md:p-6 bg-white text-left">
              <h2 className="text-2xl font-bold mb-4">Membership Benefits</h2>
              <div
                  className="flex flex-col gap-5 text-[14px] md:text-[16px]"
                    dangerouslySetInnerHTML={{ __html: information }}
                  />
            </div>
          </div>
        </div>
      </div>


      <div className="px-6 py-12 bg-white">
        <h3 className="text-2xl md:text-[26px] lg:text-[28px] font-semibold mb-4">Membership Tiers:</h3>
        <p className="mb-8 text-gray-700 text-[14px] md:text-[16px]">
          As you collect points, you'll progress through different membership
          tiers. There are four membership tiers available:
        </p>

        <div className="flex items-center justify-between gap-4 md:gap-8 relative">
            {pointsRange.map((memberStage, index) => (
                <div key={index} className="flex flex-col items-center text-center relative w-full z-10">
                
                {/* Circle */}
                <div className="w-12 h-12 rounded-full bg-blue-100 text-blue-600 font-bold flex items-center justify-center shadow">
                    {index + 1}
                </div>

                {/* Title */}
                <div className="mt-3 text-sm font-semibold text-gray-800">
                    {memberStage.title}
                </div>

                {/* Range */}
                <div className="text-xs text-gray-500">
                    {memberStage?.end
                    ? `${memberStage?.start} - ${memberStage?.end}`
                    : `≥ ${memberStage?.start}`}
                </div>

                {/* Connector */}
                {index !== pointsRange.length - 1 && (
                    <div className="absolute top-6 right-[-55%] w-full h-1 bg-gray-300 z-[-1]"></div>
                )}
                </div>
            ))}
        </div>

      </div>

      <div className="py-16 border">
        <div className="flex flex-col gap-4">
          <div className="text-[24px] md:text-[26px] lg:text-[28px] font-semibold">
            Getting started is easy
          </div>
          <span className=" text-[14px] md:text-[16px]">
            Earn Stars and get rewarded in a few easy steps.
          </span>
        </div>
        <div className="mt-12 flex items-start justify-center gap-8">
        <div className="flex flex-wrap gap-8 justify-center">
      {gainingPoints.map((item, index) => {


        return (
          <RewardStep
            key={index}
            index={index + 1}
            title={item.type}
            data={item?.data}
          />
        );
      })}
    </div>
        </div>
      </div>
    </div>
  );
}

export default Rewards;
