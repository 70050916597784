import React, { useContext, useEffect, useState } from "react";
import _axios from "../axios";
import buildLink from "../urls";
import ReactPaginate from "react-paginate";
import PointsLoader from "../components/PointsLoader";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AccountContext } from "../contexts/AccountContext";
import { AiOutlineStar } from "react-icons/ai";
import PointHistoryCard from "../components/PointHistoryCard";

function MembershipDetails() {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [membershipData, setMembershipData] = useState([]);
  const [searchParams] = useSearchParams();
  const [stateAccount, dispatch] = useContext(AccountContext);
  const [totalPages, setTotalPages] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!stateAccount.loading && !stateAccount.loged) {
      //navigate("/");
    } else if (stateAccount.loged) {
      setLoading(true);
      const page = searchParams.get("page") ?? 0;

      _axios.get(buildLink("userPoints") + "&page=" + page).then((response) => {
        try {
          setMembershipData(response.data);
          setTotalPages(response?.data?.data?.total_history_pages);
        } catch (error) {}
      });
      setLoading(false);
    }
  }, [stateAccount.loged, searchParams, page]);

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  return (
    <div className=" min-h-screen">
      {loading ? (
        <PointsLoader />
      ) : (
        <div>
          <div className="text-white px-6 py-6 bg-gradient-to-br from-[#0D143C] via-[#1E3A8A] to-[#5AB8FF]  shadow-lg">
            <p className="text-xl font-semibold mb-4 tracking-wide">
              Membership
            </p>

            <div className="flex flex-row gap-6 pb-6">
              {/* Profile Circle */}
              <div className="rounded-full w-20 h-20 md:w-24 md:h-24 text-3xl md:text-4xl font-bold border-4 border-white bg-gradient-to-b from-[#1E3A8A] to-white text-dblack flex justify-center items-center uppercase shadow-md">
                {stateAccount?.username
                  ?.replace(/\s+/g, "")
                  .charAt(0)
                  .toUpperCase()}
              </div>

              {/* Info Section */}
              <div className="flex flex-col justify-center w-[70%] md:w-full">
                <div className="flex flex-wrap mx-3 items-center justify-between mb-2">
                  <div className="flex items-center gap-3">
                    <div className="text-white text-base md:text-lg font-semibold capitalize">
                      {stateAccount?.username} {stateAccount?.lastname}
                    </div>
                    <div className="flex items-center px-1 md:px-2 md:py-1 text-sm border border-white rounded-md bg-white bg-opacity-10">
                      <AiOutlineStar className="mr-1 text-yellow-300" />
                      {membershipData?.data?.current_level || "No"} Member
                    </div>
                  </div>
                  <div className="text-white text-sm md:text-base hidden md:block">
                    <p>
                      <span className="font-medium">My Points:</span>{" "}
                      {membershipData?.data?.points_balance || 0}
                    </p>
                    <p>
                      <span className="font-medium">Pending Points:</span>{" "}
                      {membershipData?.data?.pending_points_balance || 0}
                    </p>
                  </div>
                </div>

                {/* Progress Bar */}
                <div className="mt-4 gap-2 flex items-center">
                  <div className="w-full bg-white/20 h-2 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-white transition-all duration-300"
                      style={{
                        width: `${
                          parseFloat(
                            membershipData?.data?.current_point_precentage || 0
                          ) * 100
                        }%`,
                      }}
                    ></div>
                  </div>
                  <div className="mt-1 text-sm text-white">
                    {(
                      parseFloat(
                        membershipData?.data?.current_point_precentage || 0
                      ) * 100
                    ).toFixed(2)}
                    %
                  </div>
                </div>

                <div className="mt-2 text-sm text-white/80 text-left md:text-center">
                  Earn{" "}
                  <span className="font-semibold text-white">
                    {membershipData?.data?.points_needed || "0"}
                  </span>{" "}
                  more points to become a{" "}
                  <span className="font-semibold text-white">
                    {membershipData?.data?.next_level || "-"}
                  </span>{" "}
                  Member
                </div>
              </div>
            </div>
          </div>

          <div className=" relative mx-auto px-4 py-6 bg-white rounded-b-xl shadow-sm border border-gray-200">
            {/* Header Section */}
            <div className="flex justify-between items-center w-full  mb-4 border-b pb-2">
                <h2 className="md:absolute left-1/2 transform md:-translate-x-1/2 text-gray-800 text-xl font-semibold text-dblack ">
                    Points History
                </h2>
                <div className=" flex items-center text-sm text-gray-700">
                    <span className="font-medium">Points Earned Last Month:</span>
                    <span className="font-semibold text-dblack">
                        {membershipData?.data?.points_earned_last_month || 0}
                    </span>
                </div>
                
               
            </div>


            {/* History List */}
            <div className="space-y-2">
              {membershipData?.data?.points_history.length > 0 ? (
                membershipData?.data?.points_history?.map((data, index) => (
                  <PointHistoryCard key={index} data={data} />
                ))
              ) : (
                <p className="text-sm text-gray-500">
                  No points history available.
                </p>
              )}
            </div>
          </div>
          <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={totalPages > 0 ? totalPages : 1}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  activeClassName={"active-pagination-category"}
                  containerClassName={"pagination"}
                  className={"category-pagination"}
                  forcePage={page - 1} // Adjust to start from page 1
                />
        </div>
      )}
    </div>
  );
}

export default MembershipDetails;
