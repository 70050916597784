import React, { useEffect, useState } from "react";
import Review from "../../src/assets/images/review.png";
import Account from "../../src/assets/images/account.png";
import Checkin from "../../src/assets/images/location.png";
import PopUp from "./Popup";

function RewardStep({ title, data }) {
  const [imageUrl, setImageUrl] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    if (title === "checkin info") {
      setImageUrl(Checkin);
    } else if (title === "review info") {
      setImageUrl(Review);
    } else if (title === "account info") {
      setImageUrl(Account);
    }
  }, [title]);

  return (
    <>
      <div
      className="flex flex-col justify-center bg-gray-200 items-center w-[320px] sm:w-[360px] lg:w-[380px] p-6 rounded-2xl border border-gray-200 shadow-sm hover:shadow-md transition duration-200 cursor-pointer"
      onClick={() => {
        setOpenPopUp(true)
        console.log(data);
        
      }}
      >
        <div className="w-14 h-14 mb-5 flex items-center justify-center rounded-full bg-white">
          <img src={imageUrl} className="w-6 h-6 object-contain" alt="" />
        </div>
        <div className="text-center">
          <div className="pb-1 text-[18px] font-semibold capitalize text-gray-800">
            {title}
          </div>
        </div>

      
      </div>
      <PopUp
          isOpen={openPopUp}
          onClose={() => {
            setOpenPopUp(false);
          }}
          title={title}
        >
        {title === 'checkin info' ? (
          <table className="w-full text-sm text-left text-gray-700 border border-gray-200 rounded-md">
            <thead className="bg-gray-100 text-gray-600 font-medium">
              <tr>
                <th className="px-4 py-2 border-b">Day</th>
                <th className="px-4 py-2 border-b">Points</th>
              </tr>
            </thead>
            <tbody>
              {data.map((point, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-4 py-2 border-b">Day {index + 1}</td>
                  <td className="px-4 py-2 border-b">
                    {point} point{point !== '1' ? 's' : ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : title === 'review info' ? (
          <div className="space-y-2 text-sm text-gray-700">
            {Object.values(data).map((text, i) => (
              <div key={i} className="flex gap-2 items-start">
                <span className="w-2 h-2 mt-1 rounded-full bg-blue-500 inline-block"></span>
                <span>{text}</span>
              </div>
            ))}
          </div>
        ) : title === 'account info' ? (
          <div className="text-sm text-gray-700">
            <table className="w-full border border-gray-200 rounded-md">
              <thead className="bg-gray-100 text-gray-600 font-medium">
                <tr>
                  <th className="px-4 py-2 border-b text-left">Field</th>
                  <th className="px-4 py-2 border-b text-left">Info</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data).map(([key, value], index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="px-4 py-2 border-b capitalize">{key.replace('_', ' ')}</td>
                    <td className="px-4 py-2 border-b">{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )  : null}

        </PopUp>
    </>
  
  );
}

export default RewardStep;
