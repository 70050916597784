import React from "react";
import favIcon from "../../src/assets/images/eh-icon.png";
import { FaCheckCircle, FaDollarSign, FaRegStickyNote } from "react-icons/fa";

function PointHistoryCard({ data }) {
  const width = window.innerWidth;
  console.log("width: " + width);

  return (
    <div
      className="bg-white rounded-lg overflow-auto"
      style={{
        transform: "translateY(-1%)",
        width: "99%",
        margin: "0 auto",
      }}
    >
      {width < 768 ? (
        <div>
          {data.is_user == 0 ? (
            <div className="bg-white mb-3 flex gap-4 text-lg">
              {/* First column: Name */}
              <img src={favIcon} className="w-1/4 h-fit" />
              <div className="flex flex-col">
                <div className="flex text-base items-center sm:items-center gap-2 sm:mb-0">
                  
                    <div className="">English Home</div>
                    <div className="  pr-semibold text-dgreen flex gap-1 ">
                      {data.type}
                    </div>
                  
                </div>
                <div className="text-sm text-gray-700 text-dgreyQtyProduct text-left">
                      {data.date_added}
                </div>

                <div className="flex gap-1">
                    <FaDollarSign className="mt-1" />{" "}
                    <span
                      className={`${
                        data.points > 0 ? "text-dgreen" : "text-dbase"
                      }`}
                    >
                      {data.points}
                    </span>{" "}
                  </div>
                
               
                  
                  {data.description.length > 0 && (
                    <div className="flex gap-1">
                      <FaRegStickyNote className="mt-1" /> {" "}
                      <span className="text-dgreyProduct">
                        {data.description}
                      </span>
                    </div>
                  )}
               
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-md shadow-md mb-3 p-4">
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center gap-2">
                  <div className="rounded-full text-sm">
                    <img
                      src={favIcon}
                      width={53}
                      className="bg-dbase rounded-full p-4 text-sm"
                    />
                  </div>
                  <div>
                    <div>ishtari</div>
                    <div className="text-sm text-dgreyQtyProduct">
                      {data.date_added}
                    </div>
                  </div>
                </div>
                <div className="pr-semibold text-dgreyProduct">{data.type}</div>
              </div>
              <div>
                Amount:{" "}
                <span
                  className={`${
                    data.points > 0 ? "text-dgreen" : "text-dbase"
                  }`}
                >
                  {data.points}
                </span>{" "}
              </div>
              {data.description.length > 0 && (
                <div>
                  Description:{" "}
                  <span className="text-dgreyProduct">{data.description}</span>
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          {data.is_user == 0 ? (
            <div className="bg-white mb-3 grid grid-cols-3 gap-4 text-lg">
              {/* First column: Name */}
              <div className="flex items-start sm:items-center gap-2 sm:mb-0">
                <div className="rounded-full p-2 text-sm">
                  <img src={favIcon} width={53} />
                </div>
                <div>
                  <div className="text-lg">English Home</div>
                  <div className="text-sm text-dgreyQtyProduct">
                    {data.date_added}
                  </div>
                </div>
              </div>

              {/* Second column: Type */}
              <div className="text-lg sm:text-base pr-semibold text-dgreen flex gap-1 mt-3">
                <FaCheckCircle className="mt-1" /> {data.type}
              </div>

              {/* Third column: Points and Description */}
              <div className="mt-3 text-lg">
                <div className="flex gap-1">
                  <FaDollarSign className="mt-1" /> Amount:{" "}
                  <span
                    className={`${
                      data.points > 0 ? "text-dgreen" : "text-dbase"
                    }`}
                  >
                    {data.points}
                  </span>{" "}
                </div>
                {data.description.length > 0 && (
                  <div className="flex gap-1">
                    <FaRegStickyNote className="mt-1" /> Description:{" "}
                    <span className="text-dgreyProduct">
                      {data.description}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-md shadow-md mb-3 p-4">
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center gap-2">
                  <div className="rounded-full text-sm">
                    <img
                      src={favIcon}
                      width={53}
                      className="bg-dbase rounded-full p-4 text-sm"
                    />
                  </div>
                  <div>
                    <div>ishtari</div>
                    <div className="text-sm text-dgreyQtyProduct">
                      {data.date_added}
                    </div>
                  </div>
                </div>
                <div className="pr-semibold text-dgreyProduct">{data.type}</div>
              </div>
              <div>
                Amount:{" "}
                <span
                  className={`${
                    data.points > 0 ? "text-dgreen" : "text-dbase"
                  }`}
                >
                  {data.points}
                </span>{" "}
              </div>
              {data.description.length > 0 && (
                <div>
                  Description:{" "}
                  <span className="text-dgreyProduct">{data.description}</span>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PointHistoryCard;
